<template>
  <div class="p-2">
    <div class="d-flex justify-content-center mb-4 mt-2">
      <img src="@/assets/logo.png" />
    </div>
    <b-list-group class="mb-2 shadow-sm">
      <b-list-group-item v-for="(item, idx) in items" :key="`SBI_${idx}`" :to="item.to"><icon-text :icon="item.icon">{{item.text}}</icon-text></b-list-group-item>
    </b-list-group>
    <small class="text-muted">For technical support, send an email to <a href="mailto:support@betasafe.com">support@betasafe.com</a>, or call <a href="tel:03300436562">0330 043 6562</a>.</small>
  </div>
</template>

<script>
import IconText from '@/components/IconText'

export default {
  components: { IconText },
  data: () => ({
    items: [
      { to: { name: 'dashboard' }, icon: 'columns', text: 'Dashboard' },
      { to: { name: 'accounts' }, icon: 'user-friends', text: 'Accounts' },
      { to: { name: 'guest-access' }, icon: 'hiking', text: 'Guest Access' },
      { to: { name: 'logs' }, icon: 'file-alt', text: 'Logs' }
    ]
  })
}
</script>
