<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="p-2 w-25 full-height border-right">
        <sidebar />
      </div>

      <div class="w-100 full-height bg-light">
        <div class="d-flex justify-content-between align-items-center border-bottom py-2 px-3 bg-white">
          <div class="flex-grow-1" v-if="tenant">
            <router-link :to="{ name: 'dashboard' }">{{tenant.name}}</router-link>

            <template v-for="(crumb,i) in breadcrumbs">
              <span :key="i">&nbsp;&rarr;
                <template v-if="crumb.to"><router-link :to="{ name: crumb.to }">{{crumb.title}}</router-link></template>
                <template v-else>{{crumb.title}}</template>
              </span>
            </template>
          </div>
          <div class="flex-shrink-1">
            <b-dropdown v-if="user" :text="`Logged in as ${user.name}`" variant="link">
              <b-dropdown-item @click="logout()"><icon-text icon="key">Logout</icon-text></b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <div class="d-flex flex-column">
          <div class="p-3">
            <slot />
          </div>
          <div class="text-center text-muted">
            <small>Copyright &copy; <a href="https://betasafe.com" target="_blank">BetaSafe Information Services Limited</a> 2021.  All Rights Reserved.</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.full-height {
  min-height: 100vh;
}
</style>

<script>
import Sidebar from '../components/Sidebar'
import IconText from '../components/IconText'
import { mapGetters } from 'vuex'

export default {
  components: { Sidebar, IconText },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      tenant: 'tenant/tenant',
      isLoggedIn: 'auth/isLoggedIn'
    }),
    breadcrumbs () {
      return this.$route.meta.breadcrumbs === undefined ? [] : this.$route.meta.breadcrumbs
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
